import Carousel from 'react-bootstrap/Carousel';

// 顶层广告
var heroData = [
  {
    id: 1,
    image: require('../assets/images/heros/img-myhero-01.jpg'),
    title: '因为专业，所以卓越',
    description: '“因为专业，所以卓越”——这不仅是一句简单的广告词，更是我们企业精神的真实写照。在这个充满竞争的时代，我们深知专业的重要性。我们拥有一支由行业精英组成的团队，他们凭借深厚的专业知识、丰富的行业经验和不懈的创新精神，始终走在行业前沿。正因为我们坚持专业，才能在产品和服务上不断追求卓越，为客户提供超越期待的价值。选择我们，就是选择了一份专业保障，一个卓越的未来。',
    link: 'https://www.google.com'
  },
  {
    id: 2,
    image: require('../assets/images/heros/img-myhero-02.jpg'),
    title: '匠心独运，艺术之作，精雕细琢',
    description: '坚持以工匠精神来打磨每一款产品，将科技创新与艺术美学完美结合。我们的研发团队秉承匠心独运的思维方式，不断探索和挑战技术的极限，将每一项技术都打磨得如同艺术品般精致。每一款科技产品都是我们精雕细琢的成果，它们不仅功能强大，更在设计上追求极致的美感。选择我们的科技产品，就是选择了一件融合了工匠精神与艺术美学的杰作，让科技因匠心而温暖，因艺术而生动。',
    link: 'https://www.facebook.com'
  },
  {
    id: 3,
    image: require('../assets/images/heros/img-myhero-03.jpg'),
    title: '体验非凡技术，领略科技的无尽魅力',
    description: '邀请您踏上一场感官与智慧的盛宴。在这里，我们不仅仅是技术的创造者，更是未来科技的引领者。我们的产品融合了最前沿的技术创新，旨在为您带来超越期待的体验。每一次操作，每一次互动，都是对科技深度和广度的全新探索。通过我们的技术，您将领略到科技的无尽魅力，感受到它如何无缝融入您的产品，提升效率，丰富体验，让想象快速变为现实。选择我们，让我们一起见证科技的力量，开启非凡体验的新篇章。',
    link: 'https://www.twitter.com'
  }
]

function AppHero() {
  return (
    <section id="home" className="hero-block">
       <Carousel>
          {
            heroData.map(hero => {
              return (
                <Carousel.Item key={hero.id}>
                  <img
                    className="d-block w-100"
                    src={hero.image}
                    alt={"slide " + hero.id}
                  />
                  <Carousel.Caption>
                    <h1>{hero.title}</h1>
                    <p>{hero.description}</p>
                    <a className="btn btn-primary" href={hero.link}>Buy now <i className="fas fa-chevron-right"></i></a>
                  </Carousel.Caption>             
                </Carousel.Item>
              );
            })
          }
      </Carousel>
    </section>
  );
}

export default AppHero;